import styled from 'styled-components'

export const StyleBase = styled.div`
  margin: 50px 0;
  > h2 {
    color: ${props => props.theme.darkGrey};
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 600;
  }
`
