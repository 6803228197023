import React, { Component } from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import { verifyToken } from '../../actions/company'

class VerifyToken extends Component {
  componentDidMount () {
    // eslint-disable-next-line
    const [ _, query ] = this.props.history.location.search.split('?')
    const { token } = qs.parse(query)

    if (!token) {
      this.props.history.push('/')
    } else {
      this.props.verifyToken(token)
    }
  }
  render () {
    const { company: { loading, currentAdmin } } = this.props
    if (loading) {
      return null
    }
    if (currentAdmin) {
      return <Redirect to="/dashboard" />
    } else {
      return <Redirect to="/" />
    }
  }
}

const mapStateToProps = ({ company }) => ({ company })

const mapStateToDispatch = dispatch => bindActionCreators({ verifyToken }, dispatch)

export default connect(mapStateToProps, mapStateToDispatch)(VerifyToken)
