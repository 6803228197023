import styled from 'styled-components'

export const StyleBase = styled.div`
  padding-bottom: 60px;
  .DayPicker {
    margin-top: 40px;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 20px 30px;
  }
  .DayPicker-Day {
    border-radius: 4px;
    padding: 10px 25px;
    font-size: 13px;
  }
  .DayPicker-Caption {
    text-align: center;
    margin-top: -22px;
    position: absolute;
    width: 100%;
    top: 20px;
    left: 0;
  }
  .DayPicker-Day--range:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #DFDCF8;
    color: ${props => props.theme.primary};
  }
  .DayPicker-Day--offDays:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #FDEDD3;
    color: #F5A623;
    &:hover {
      .tooltip-reason {
        opacity: 1;
        visibility: visible;
      }
    }
  };
  .DayPicker-Day--sickDays:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #ecf3fc;
    color: #5698e4;
    &:hover {
      .tooltip-reason {
        opacity: 1;
        visibility: visible;
      }
    }
  };
  .DayPicker-Day--nationalDaysOff:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
  .DayPicker-Day--holidays:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #D4F0DD;
    color: #3DCD58;
    &:hover {
      .tooltip-reason {
        opacity: 1;
        visibility: visible;
      }
    }
  };
`

export const ToolTip = styled.div`
  position: absolute;
  top: -50px;
  left: -40px;
  z-index: 99;
  padding: 10px;
  border-radius: 4px;
  background-color: #0B0826;
  font-size: .8em;
  color: #FFF;
  margin: 0 auto;
  text-align: center;
  width: auto;
  padding: 5px 10px;
  white-space: nowrap;
  opacity: 0;
  transition: .15s opacity ease;
  visibility: hidden;
  &:after {
    top: 100%;
    left: 15px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #0B0826;
    border-width: 5px;
  }
`

export const CalendarLegend = styled.div`
  display: flex;
  margin: 30px auto 0;
  justify-content: space-around;
  > div {
    display: flex;
    & > div:first-child {
      height: 20px;
      width: 20px;
      border-radius: 50px;
    }
  }
  .legend__work-days {
    & > div:first-child {
      background-color: #DFDCF8;
    }
  }
  .legend__national-days {
    & > div:first-child {
      background-color: #D4F0DD;
    }
  }
  .legend__leave-days {
    & > div:first-child {
      background-color: #FDEDD3;
    }
  }
  .legend__sick-days {
    & > div:first-child {
      background-color: #ecf3fc;
    }
  }
  .legend__description {
    span {
      display: block;
      margin-left: 10px;
      line-height: 15px;
      &:last-first {
        font-size: 15px;
      }
      &:last-child {
        font-size: 12px;
        color: #8B9898;
      }
    }
  }
`
