import React from 'react'

import { StyleBase, ProfilePic, ProfileDetails } from './style'

const ProfileCard = ({
  avatar,
  name,
  job_title,
  email,
  joined_at,
  local_tz,
  bio
}) => {
  return (
    <StyleBase>
      <ProfilePic avatar={avatar}>
        <h4>{name}</h4>
      </ProfilePic>
      <ProfileDetails>
        <div className="profile__role">{job_title}</div>
        <div className="profile__email">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="icon-mail">
            <path
              fill="#9B9B9B"
              className="primary"
              d="M22 8.62V18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.62l9.55 4.77a1 1 0 0 0 .9 0L22 8.62z"
            />
            <path
              fill="#9B9B9B"
              className="secondary"
              d="M12 11.38l-10-5V6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v.38l-10 5z"
            />
          </svg>
          <span>{email}</span>
        </div>
        <div className="profile__joined-date">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="icon-calendar-date">
            <path
              fill="#9B9B9B"
              className="primary"
              d="M5 4h14a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm0 5v10h14V9H5z"
            />
            <path
              fill="#9B9B9B"
              className="secondary"
              d="M13 13h3v3h-3v-3zM7 2a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zm10 0a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1z"
            />
          </svg>
          <span>{joined_at}</span>
        </div>
        <div className="profile__local-tz">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="icon-globe">
            <circle fill="#9B9B9B" cx="12" cy="12" r="10" className="primary" />
            <path
              fill="#D9D9D9"
              className="secondary"
              d="M2.05 11A10 10 0 0 1 15 2.46V6a2 2 0 0 1-2 2h-1v1a2 2 0 0 1-1 1.73V12h2a2 2 0 0 1 2 2v1h2a2 2 0 0 1 2 2v2.14A9.97 9.97 0 0 1 12 22v-4h-1a2 2 0 0 1-2-2v-2a2 2 0 0 1-2-2v-1H2.05z"
            />
          </svg>
          <span>{local_tz}</span>
        </div>
        <div className="profile__bio">
          <p>
            {bio}
          </p>
        </div>
      </ProfileDetails>
    </StyleBase>
  )
}

export default ProfileCard
