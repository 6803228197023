import React from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { signOut } from '../../../actions/company'

import { StyleBase, LogoLink, Navigation, LogoutBtn } from './style'

const NavBar = ({signOut}) => {
  return (
    <StyleBase>
      <LogoLink>
        <Link to="/dashboard/info">
          <img src={ require('../../../assets/Pop_dark.svg') } style={ { height: '40px' } } alt='Pop' />
        </Link>
      </LogoLink>
      <Navigation>
        <li>
          <NavLink exact to="/dashboard/team">
            Team
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/dashboard/leaves">
            Leave
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/dashboard/timesheet">
            Timesheet
          </NavLink>
        </li>
      </Navigation>
      <LogoutBtn>
        <span onClick={signOut}>Logout</span>
      </LogoutBtn>
    </StyleBase>
  )
}

const mapDispatchToState = dispatch => bindActionCreators({ signOut }, dispatch)

export default withRouter(connect(null, mapDispatchToState)(NavBar))

