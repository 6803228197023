import styled from 'styled-components'

export const StyleBase = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px 20px;
  margin-bottom: 50px;
`

export const EmptyState = styled.div`
  text-align: center;
  max-width: 420px;
  color: ${props => props.theme.grey};
  margin: 50px auto;
  p {
    color: ${props => props.theme.grey};
  }
  svg {
    height: 90px;
  }
`
