import React, { Component } from 'react'
import { connect } from 'react-redux'

import { StyleBase, TeamContainer } from './style'
import ProfileCard from '../../components/ProfileCard'
import format from 'date-fns/format'

const calcLocalTime = offset => {
  const d = new Date()
  const utc = d.getTime() + d.getTimezoneOffset() * 60000

  return new Date(utc + 3600000 * offset)
}
class Team extends Component {
  render() {
    const {
      company: { team, name }
    } = this.props
    return (
      <StyleBase>
        <h2>Members</h2>
        <p>
          Here you can find all the awesome team members who are currently busy
          building/working on{' '}
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            {name}
          </span>
        </p>
        <TeamContainer>
          {team.map(x => (
            <ProfileCard
              key={x._id}
              {...x.member}
              joined_at={format(x.joined_at, 'MMM. DD, YYYY')}
              local_tz={
                format(calcLocalTime(x.member.tz_offset / 3600), 'HH:mm A') +
                ' local time'
              }
            />
          ))}
        </TeamContainer>
      </StyleBase>
    )
  }
}

const mapStateToProps = ({ company: { company } }) => ({ company })

export default connect(mapStateToProps)(Team)
