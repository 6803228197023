import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import EnsureAuth from "../utils/EnsureAuth"
import Login from "../views/login"
import VerifyToken from "../views/verifyToken"
import RedirectToMobile from "../views/RedirectToMobile"
import Dashboard from "./dashboard"

const Pop = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/signin" component={VerifyToken} />
        <Route path="/mobile" component={RedirectToMobile} />
        <EnsureAuth path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  )
}

export default Pop
