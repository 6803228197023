import React from 'react'

import Button from '../Button'
import {
  StyleBase,
  RequestHeader,
  RequesterProfile,
  RequestStatus,
  LeaveDuration,
  RequestReason,
  RequestFooter
} from './style'

const RequestCard = ({ requester, duration, status, start_date, end_date, createdAt, reason, handleApprovedRequest, handleRejectedRequest }) => {
  return (
    <StyleBase>
      <RequestHeader>
        <RequesterProfile>
          <img src={requester.avatar} alt={requester.name} />
          <div>
            <h5>{requester.name}</h5>
            <span>{createdAt}</span>
          </div>
        </RequesterProfile>
        <RequestStatus status={status}>
          {status}
        </RequestStatus>
      </RequestHeader>
      <LeaveDuration>
        <h2>{duration} Days</h2>
        <div>
          <span>{start_date}</span>
          <span> - </span>
          <span>{end_date}</span>
        </div>
      </LeaveDuration>
      <RequestReason>
        <label>Reason</label>
        <p>
          {reason || <span style={{fontStyle: 'italic', opacity: 0.7}}>No reason provided</span>}
        </p>
      </RequestReason>
      {
        status === 'pending' && (
          <RequestFooter>
            <Button onClick={handleRejectedRequest}>Deny</Button>
            <Button onClick={handleApprovedRequest} btnStyle="primary">Approve</Button>
          </RequestFooter>
        ) 
      }
    </StyleBase>
  )
}

export default RequestCard
