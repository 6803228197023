import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import rootReducer from './reducers'
import Router from './routes'
import theme from './styles/theme'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const GlobalStyle = createGlobalStyle`
  *,:after,:before{box-sizing:border-box;}
  body, html {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: "Roboto", sans-serif;
    background-color: #F5F6FA;
    color: #21233f;
    line-height: 1.4;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
  }
`

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Router />
      </>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
