import styled, { css } from 'styled-components'

export const StyleBase = styled.div`
  padding: 20px;
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 4px;
  background-color: #FFF;
`

export const RequestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const RequesterProfile = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  h5 {
    margin: 0;
    line-height: 15px;
    font-size: 16px;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: #9B9B9B;
  }
`

const statusStyles = {
  pending: css`
    background-color: #FDEDD3;
    color: #F5A623;
  `,
  approved: css`
    background-color: #D4F0DD;
    color: #3DCD58;
  `,
  denied: css`
    background-color: #F5D2D8;
    color: #E23943;
  `,
  cancelled: css`
    background-color: rgba(218, 218, 218, 0.5);
    color: #4F4F4F;
  `,
}

export const RequestStatus = styled.div`
  ${props => statusStyles[props.status]};
  border-radius: 20px;
  flex-grow: 0;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`

export const LeaveDuration = styled.div`
  text-align: center;
  margin: 20px 0;
  h2 {
    color: #6050DC;
    margin: 0;
    font-size: 26px;
  }
  span {
    color: #9B9B9B;
    font-size: 14px;
  }
`

export const RequestReason = styled.div`
  label {
    color: #9B9B9B;
    font-size: 12px;
  }
  p {
    margin: 0;
    font-size: 15px;
  }
  margin-bottom: 30px;
`

export const RequestFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: auto;
    padding: 8px 20px;
    font-size: 13px;
    &:last-child {
      margin-left: 10px;
    }
  }
`
