import { combineReducers } from 'redux'
import { USER_SIGN_OUT } from '../constants'

import company from './company'
import leaveRequests from './requests'
import holidays from './holidays'

const reducers = combineReducers({
  company,
  leaveRequests,
  holidays
})

const rootReducer = (state, action) => {
  if (action.type === USER_SIGN_OUT) {
    state = undefined
  }
  return reducers(state, action)
}

export default rootReducer
