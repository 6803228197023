import isBefore from 'date-fns/is_before'
import sortBy from 'lodash/sortBy'

import {
  GET_REQUESTS,
  GET_REQUESTS_FAILED,
  UDATE_REQUEST,
  UDATE_REQUEST_SUCCESS,
  UDATE_REQUEST_FAILED
} from '../constants'

const initialState = {
  loading: true,
  upcoming: [],
  allRequests: [],
  error: null,
  updateLeave: {
    loading: false,
    error: null
  }
}

export default function leaveRequests(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_REQUESTS:
      const allRequests = payload.requests
      const upcoming = sortBy(
        allRequests.filter(request =>
          isBefore(new Date(), new Date(request.start_date))
          && request.status !== 'denied'
        ),
        o => o.start_date
      )
      return {
        ...state,
        loading: false,
        upcoming,
        allRequests,
        error: null
      }

    case GET_REQUESTS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error
      }

    case UDATE_REQUEST:
      return {
        ...state,
        updateLeave: {
          loading: true,
          error: null
        }
      }

    case UDATE_REQUEST_SUCCESS:
      const updatedRequest = payload.request
      const requests = state.allRequests.map(request =>
        request._id === updatedRequest._id
          ? { ...request, status: updatedRequest.status }
          : request
      )
      const upcomingRequests = requests.filter(request =>
        isBefore(new Date(), new Date(request.start_date))
        && request.status !== 'denied'
      )
      return {
        ...state,
        allRequests: requests,
        upcoming: upcomingRequests,
        updateLeave: {
          loading: false,
          error: null
        }
      }

    case UDATE_REQUEST_FAILED:
      return {
        ...state,
        updateLeave: {
          loading: false,
          error: payload.error
        }
      }

    default:
      return state
  }
}
