import styled, { css } from 'styled-components'

export const StyleBase = styled.div`
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 8px;
  overflow: hidden;
`

const statusStyles = {
  pending: css`
    background-color: #FDEDD3;
    color: #F5A623;
  `,
  approved: css`
    background-color: #D4F0DD;
    color: #3DCD58;
  `,
  denied: css`
    background-color: #F5D2D8;
    color: #E23943;
  `,
  cancelled: css`
    background-color: rgba(218,218,218,0.5);
    color: #4F4F4F;
  `,
}

export const RequestStatus = styled.div`
  ${props => statusStyles[props.status]};
  border-radius: 20px;
  flex-grow: 0;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
`

export const RowTable = styled.div`
  &:nth-child(odd) {
    background-color: #F5F6FA;
  }
  &:nth-child(even) {
    background-color: #FFF;
  }
  &:first-child {
    > span {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      color: ${props => props.theme.grey};
    }
  }
  display: flex;
  align-items: flex-start;
  > span {
    padding-top: 16px;
    padding-bottom: 16px;
    color: ${props => props.theme.darkGrey};
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    &:nth-child(1) {
      width: 30%;
      padding: 16px 10px 16px 30px;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(6) {
      width: 15%;
    }
  }
  &.table-value {
    span:first-child {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 30px;
      span {
        margin-left: 15px;
      }
    }
    img {
      border-radius: 50%;
    }
  }
`
