import qs from "qs"

export default function RedirectToMobile(props) {
  // eslint-disable-next-line
  const [_, query] = props.history.location.search.split("?")
  const { token, refToken } = qs.parse(query)
  console.log(token)
  window.location.href = `com.gemography://login/${token}/${refToken}`
  return null
}
