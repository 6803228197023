import styled from 'styled-components'

export const StyleBase = styled.div`
  margin-top: 50px;
  h2 {
    color: ${props => props.theme.darkGrey};
    margin: 0;
    font-size: 16px;
  }
  p {
    color: ${props => props.theme.grey};
    margin: 10px 0 0;
  }
`

export const TeamContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 50px 0px;
  gap: 30px;
`
