import { TOKEN_VERIFIED, TOKEN_VERIFIED_FAILED, USER_SIGN_OUT } from '../constants'

export const verifyToken = (token) => {
  return async dispatch => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/companies/verify-cto-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })
    const body = await response.json()
    if (body.ok) {
      localStorage.setItem('Authorization', body.token)
      dispatch({
        type: TOKEN_VERIFIED,
        payload: body
      })
    } else {
      dispatch({
        type: TOKEN_VERIFIED_FAILED,
        payload: body
      })
    }
  }
}

export const signOut = _ => {
  localStorage.removeItem('Authorization')
  return {
    type: USER_SIGN_OUT
  }
}
