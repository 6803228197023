import React, { Component } from 'react'
import DayPicker from 'react-day-picker'
import isSameMonth from 'date-fns/is_same_month'
import isSameDay from 'date-fns/is_same_day'
import isBefore from 'date-fns/is_before'
import isAfter from 'date-fns/is_after'
import isWeekend from 'date-fns/is_weekend'
import addDays from 'date-fns/add_days'
import isWithinRange from 'date-fns/is_within_range'
import 'react-day-picker/lib/style.css'

import { StyleBase, ToolTip, CalendarLegend } from './style'

function Navbar({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils
}) {
  const styleLeft = {
    position: 'absolute',
    cursor: 'pointer',
    border: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 99
  }
  const styleRight = {
    position: 'absolute',
    cursor: 'pointer',
    border: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 99
  }
  return (
    <div className={className}>
      <button style={styleLeft} onClick={() => onPreviousClick()}>
        {/* ← {prev.slice(0, 3)} */}
        <svg width="7" height="11" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.294 10.588L0 5.294 5.294 0l1.031.963-4.331 4.33 4.331 4.332z" fill="#909090" fillRule="nonzero"/>
        </svg>
    
      </button>
      <button style={styleRight} onClick={() => onNextClick()}>
        {/* {next.slice(0, 3)} → */}
        <svg width="7" height="11" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.706 10.588L7 5.294 1.706 0 .675.963l4.331 4.33L.675 9.626z" fill="#909090"/>
        </svg>
      </button>
    </div>
  )
}

class CalendarView extends Component {
  constructor() {
    super()
    this.MOROCCAN_OFF_DAYS = [
      `/1/1`, // new year's day
      `/2/11`, // independence manifesto
      `/5/1`, // 3id choghl?
      `/7/30`, // king Mo VI coronation in 99
      `/8/14`, // Oued ed-dahab day
      `/8/20`, // Revolution day
      `/8/21`, // Youth day
      `/11/06`, // Green March
      `/11/18` // independence day
    ]
    this.state = {
      toolTipShown: false,
      workDays: 0,
      sickDays: 0,
      nationalDays: 0,
      offDays: 0
    }
  }

  handleDayMouseEnter = (day, { nationalDaysOff, offDays }) => {
    if (nationalDaysOff || offDays) {
      this.toggleToolTip()
    }
  }

  toggleToolTip = () => {
    this.setState({ toolTipShown: !this.state.toolTipShown })
  }

  componentDidMount() {
    const workDays = this._getDaysInMonth({
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    })
    const newState = { workDays, ...this._getNonWorkDaysInMonth() }
    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      const workDays = this._getDaysInMonth({
        year: new Date().getFullYear(),
        month: new Date().getMonth()
      })
      const newState = { workDays, ...this._getNonWorkDaysInMonth() }
      this.setState(newState)
    }
  }

  handleChange = day => {
    const workDays = this._getDaysInMonth({
      year: day.getFullYear(),
      month: day.getMonth()
    })
    const newState = { workDays, ...this._getNonWorkDaysInMonth(day) }
    this.setState(newState)
  }

  _getNonWorkDaysInMonth = (day = new Date()) => {
    const { holidays, offDays } = this.props
    const relgiousHolidays = holidays.filter(({ date }) =>
      isSameMonth(date, day)
    ).length
    const nationalDays = this.MOROCCAN_OFF_DAYS.filter(date => {
      const fullDate = new Date(day).getFullYear() + date
      return isSameMonth(fullDate, day) && !isWeekend(fullDate)
    }).length

    const totalHolidays = relgiousHolidays + nationalDays

    const sickDays = offDays
      .filter(x => x.type === 'sick' && isSameMonth(x.from, day))
      .reduce((acc, curr) => acc + curr.duration, 0)

    const leaveDays = offDays
      .filter(x => x.type === 'leave' && isSameMonth(x.from, day))
      .reduce((acc, curr) => acc + curr.duration, 0)

    return {
      nationalDays: totalHolidays,
      sickDays,
      offDays: leaveDays
    }
  }

  // sauce: https://stackoverflow.com/a/39296204/4880322
  _getDaysInMonth = ({ month, year }) => {
    const date = new Date(year, month, 1)
    const { holidays, offDays, startDate } = this.props
    let days = 0

    const nationalHolidays = [
      ...this.MOROCCAN_OFF_DAYS.map(x => new Date(`${year}/${x}`)),
      ...holidays.map(x => new Date(x.date)) // religious days
    ]

    while (date.getMonth() === month && isBefore(date, new Date())) {
      // Exclude weekends
      const tmpDate = new Date(date)
      const weekDay = tmpDate.getDay() // week day

      if (
        weekDay % 6 && // exclude 0=Sunday and 6=Saturday
        !nationalHolidays.find(nDay => isSameDay(nDay, date)) && // exclude holidays
        !offDays.find(
          x => isWithinRange(date, new Date(x.from), new Date(x.to)) // exclude off days
        ) &&
        isAfter(date, addDays(new Date(startDate), -1))
      ) {
        days++
      }

      date.setDate(date.getDate() + 1)
    }

    return days
  }

  render() {
    const { offDays, startDate, endDate, holidays } = this.props
    const range = {
      from: startDate,
      to: endDate ? endDate : new Date()
    }

    const modifiers = {
      range,
      offDays: offDays
        .filter(x => x.type === 'leave')
        .map(({ from, to }) => ({
          from: new Date(from),
          to: addDays(new Date(to), -1)
        })),
      sickDays: offDays
        .filter(x => x.type === 'sick')
        .map(({ from, to }) => ({
          from: new Date(from),
          to: addDays(new Date(to), -1)
        })),
      nationalDaysOff: day =>
        this.MOROCCAN_OFF_DAYS.filter(date => {
          const fullDate = new Date(day).getFullYear() + date
          return isSameDay(fullDate, day) && isSameMonth(fullDate, day)
        })[0],
      holidays: holidays.map(({ date }) => new Date(date))
    }
    return (
      <StyleBase>
        <DayPicker
          modifiers={modifiers}
          disabledDays={[{ daysOfWeek: [0, 6] }]}
          onDayMouseEnter={this.handleDayMouseEnter}
          onMonthChange={this.handleChange}
          navbarElement={<Navbar />}
          renderDay={day =>
            renderDay(day, offDays, this.MOROCCAN_OFF_DAYS, holidays)
          }
        />
        <CalendarLegend>
          <div className="legend__work-days">
            <div />
            <div className="legend__description">
              <span>{this.state.workDays}</span>
              <span>Work Days</span>
            </div>
          </div>
          <div className="legend__national-days">
            <div />
            <div className="legend__description">
              <span>{this.state.nationalDays}</span>
              <span>National Days</span>
            </div>
          </div>
          <div className="legend__leave-days">
            <div />
            <div className="legend__description">
              <span>{this.state.offDays}</span>
              <span>Leave Days</span>
            </div>
          </div>
          <div className="legend__sick-days">
            <div />
            <div className="legend__description">
              <span>{this.state.sickDays}</span>
              <span>Sick Days</span>
            </div>
          </div>
        </CalendarLegend>
      </StyleBase>
    )
  }
}

function renderDay(day, offDays, nationalHolidays, holidays) {
  const request = offDays.filter(
    d =>
      isAfter(day, new Date(d.from)) &&
      isBefore(day, new Date(d.to)) &&
      !isWeekend(day)
  )[0]

  const isDayOff = !!request

  const date = day.getDate()

  const isHoliday = !!holidays.filter(({ date }) =>
    isSameDay(day, new Date(date))
  )[0]
  const isNationalHoliday = !!nationalHolidays.filter(date => {
    const fullDate = new Date(day).getFullYear() + date
    return (
      isSameDay(fullDate, new Date(day)) && isSameMonth(fullDate, new Date(day))
    )
  })[0]

  return (
    <div style={{ position: 'relative' }}>
      <div>{date}</div>
      {isDayOff && (
        <ToolTip className="tooltip-reason">
          {request.reason || 'Reason unspecified'}
        </ToolTip>
      )}
      {(isNationalHoliday || isHoliday) && (
        <ToolTip className="tooltip-reason">National Holiday</ToolTip>
      )}
    </div>
  )
}

export default CalendarView
