import React, { Component } from 'react'

import { StyleBase, Info } from './style'

import Button from '../../components/Button'
import Input from '../../components/Input'

class LoginView extends Component {
  state = {
    email: '',
    emailError: null,
    emailSent: false
  }

  onInputChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value, emailError: null, emailSent: false })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { email } = this.state
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!emailRegex.test(email)) {
      this.setState({ emailError: 'Please enter a valid address email' })
    } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/companies/cto-signin`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({email})
        })
        const body = await response.json()
        if (body.ok) {
          this.setState({ emailSent: true })
        } else {
          this.setState({ emailSent: false, emailError: body.error})
        }
      } catch (error) {
        console.log(error)
        this.setState({ emailError: 'Opps! something went wrong.'})
      }
    }
  }


  render() {
    const { email, emailError, emailSent } = this.state
    return (
      <StyleBase>
        <img src={ require('../../assets/gemo.svg') } alt='Gemography' style={ { 'height' : '41px' } } />
        <form onSubmit={this.handleSubmit}>
          <h1>Login</h1>
          {
            emailSent && (
              <Info status="success">
                <span>We have just sent you a magic link.<br/>Please check your inbox.</span>
              </Info>
            )
          }
          <Input
            onChange={this.onInputChange}
            value={email}
            label='Email'
            placeholder='john@doe.com'
            error={emailError}
            name='email' />
          <Button btnStyle="primary">Send magic link</Button>
        </form>
      </StyleBase>
    )
  }
}

export default LoginView
