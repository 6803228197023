import styled from 'styled-components'

export const StyleBase = styled.div`
  padding: 19px 40px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.secondary};
  box-shadow: 10px 0 30px rgba(0,0,0,.05);
`

export const LogoLink = styled.div`
  text-align: center;
`

export const Navigation = styled.ul`
  list-style: none;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  li a {
    text-decoration: none;
    color: inherit;
    font-size: 15px;
    margin-right: 60px;
    border-bottom: 4px solid transparent;
    color: #FFF;
    transition: color .15s ease-in;
    &:not(.active) {
      opacity: .5;
    }
    &:not(.active):hover {
      opacity: .9;
    }
  }
`

export const LogoutBtn = styled.div`
  text-align: center;
  cursor: pointer;
  color: #FFF;
  font-size: 15px;
  a {
    color: inherit;
  }
`
