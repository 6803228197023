import styled, { css } from 'styled-components'

export const StyleBase = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  h2 {
    color: ${props => props.theme.darkGrey};
    margin: 0;
    font-size: 16px;
  }
  p {
    color: ${props => props.theme.grey};
    margin: 10px 0 0;
  }
`

export const Container = styled.div`
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.lightGrey};
  margin-top: 50px;
  > div:nth-child(2) {
    margin: 0 auto;
  }
  display: flex;
`

export const UsersList = styled.div`
  border-right: 1px solid ${props => props.theme.lightGrey};
  padding-top: 16px;
`

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px 20px;
  border-bottom: 1px solid ${props => props.theme.lightGrey};
  border-left-style: solid;
  border-left-width: 3px;
  border-left-color: transparent;
  ${props => props.isSelected && css`
    border-left-color: ${props.theme.primary};
    background-color: rgba(222, 222, 223, 0.33);
  `}
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  > div {
    margin-left: 10px;
    h3 {
      font-size: 16px;
      margin: 0;
    }
    span {
      color: ${props => props.theme.grey};
      font-size: 12px;
    }
  }
`