import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isAfter from 'date-fns/is_after'
import format from 'date-fns/format'

import { getLeaveRequests } from '../../actions/requests'
import { getHolidays } from '../../actions/holidays'
import CalendarView from '../../components/CalendarView'

import { UsersList, StyleBase, UserProfile, Container } from './style'

class TimeSheet extends Component {
  state = {
    selectedProfile: null,
    toolTipShown: false
  }

  toggleTooltip = () => {
    this.setState({ toggleTooltip: !this.state.toolTipShown })
  }

  componentDidMount() {
    this.props.getLeaveRequests()
    this.props.getHolidays()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { company } = nextProps
    return prevState.selectedProfile ? {} : { selectedProfile: company.team[0] }
  }

  handleSelectProfile = selectedProfile => {
    this.setState({ selectedProfile })
  }

  render() {
    const { company, leaveRequests, holidays } = this.props
    const { selectedProfile } = this.state
    if (leaveRequests.loading || holidays.loading) {
      return ''
    }
    const offDays = leaveRequests.allRequests
      .filter(request => request.requester._id === selectedProfile.member._id)
      .filter(request => isAfter(new Date(), new Date(request.start_date)))
      .map(({ start_date, end_date, reason, type, duration }) => ({
        from: start_date,
        to: end_date,
        reason,
        type,
        duration
      }))

    return (
      <StyleBase>
        <h2>Timesheet</h2>
        <p>Select a Hacker to see their timesheet including work days, sick days, leave days and public holidays. navigate between months to see previous timesheets.</p>
        <Container>
          <UsersList>
            {company.team.map(x => (
              <UserProfile
                isSelected={x._id === selectedProfile._id}
                key={x._id}
                onClick={() => this.handleSelectProfile(x)}
              >
                <img src={x.member.avatar} alt={x.member.name} />
                <div>
                  <h3>{x.member.name}</h3>
                  <span>
                    {format(x.joined_at, 'MMM. DD, YYYY')} -{' '}
                    {x.left_at ? format(x.left_at, 'MMM. DD, YYYY') : 'Today'}
                  </span>
                </div>
              </UserProfile>
            ))}
          </UsersList>
          <CalendarView
            startDate={new Date(selectedProfile.joined_at)}
            endDate={
              selectedProfile.left_at ? new Date(selectedProfile.left_at) : null
            }
            holidays={holidays.list}
            offDays={offDays}
            userId={selectedProfile._id}
          />
        </Container>
      </StyleBase>
    )
  }
}

const mapStateToProps = ({
  company: { company },
  leaveRequests,
  holidays
}) => ({
  company,
  leaveRequests,
  holidays
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getLeaveRequests, getHolidays }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSheet)
