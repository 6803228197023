import {
  GET_HOLIDAYS,
  GET_HOLIDAYS_FAILED
} from '../constants'

export const getHolidays = _ => {
  return async dispatch => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/holidays`
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_HOLIDAYS,
        payload: body
      })
    } else {
      dispatch({
        type: GET_HOLIDAYS_FAILED,
        payload: body
      })
    }
  }
}
