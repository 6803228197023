import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getLeaveRequests } from '../../actions/requests'
import { StyleBase } from './style'
import UpComingRequests from './upcoming'
import LeaveRequestsTable from './allLeaveRequests'

class LeavesView extends Component {
  componentDidMount () {
    this.props.getLeaveRequests()
  }
  render () {
    const { leaveRequests: { loading, upcoming, allRequests, error } } = this.props
    if (loading) {
      return null
    }
    if (error) {
      return 'Ooops! something went wrong'
    }
    console.log(upcoming)
    return (
      <StyleBase>
        <h2>Upcoming leaves</h2>
        <UpComingRequests requests={allRequests.filter(req => req.status !== 'cancelled' && req.status !== 'denied' && req.status !== 'confirmed')} />
        <h2>All leave requests</h2>
        <LeaveRequestsTable requests={allRequests} />
      </StyleBase>
    )
  }
}

const mapStateToProps = ({ leaveRequests }) => ({ leaveRequests })

const mapDispatchToProps = dispatch => bindActionCreators({ getLeaveRequests }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LeavesView)
