import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import NavBar from './navbar'
import Team from '../../views/team'
import Leaves from '../../views/leaves'
import TimeSheet from '../../views/timesheet'
import { AppLayout, MainContent } from './style'

class Dashboard extends Component {
  componentDidMount() {
    document.title = `${this.props.company.company.name} | People Ops Platform`
  }
  render () {
    return (
      <AppLayout>
        <NavBar />
        <MainContent>
          <Switch>
            <Route path='/dashboard/team' component={Team} />
            <Route path='/dashboard/leaves' component={Leaves} />
            <Route path='/dashboard/timesheet' component={TimeSheet} />
            <Redirect from='/dashboard' to='/dashboard/team' />
          </Switch>
        </MainContent>
      </AppLayout>
    )
  }
}

export default Dashboard
