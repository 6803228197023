import styled, { css } from 'styled-components'

export const StyleBase = styled.div`
  max-width: 420px;
  margin: 15vh auto;
  h1 {
    margin: 50px 0 30px
  }
`

const styles = {
  success: css`
    border: 1px solid #3DCD58;
    background-color: #D4F0DD;
    color: #3DCD58;
  `,
  error: css`
    border: 1px solid #e74c3c;
    background-color: rgba(231, 76, 60, .2);
    color: ##e74c3c;
  `
}

export const Info = styled.div`
  ${props => styles[props.status]}
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 14px;
`
