import {
  GET_HOLIDAYS,
  GET_HOLIDAYS_FAILED
} from '../constants'

const initialState = {
  loading: true,
  error: null,
  list: []
}

export default function holidays(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_HOLIDAYS:
      return {
        ...state,
        list: payload.holidays ,
        loading: false
      }

    case GET_HOLIDAYS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error
      }

    default:
      return state
  }
}
