import styled from 'styled-components'

export const StyleBase = styled.div`
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.lightGrey};
`

export const ProfilePic = styled.div`
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0) 34%, rgba(0,0,0,0.2) 66%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0.6)), url(${props => props.avatar});
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  position: relative;
  h4 {
    margin: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #FFF;
  }
`

export const ProfileDetails = styled.div`
  padding: 15px 20px 30px;
  > div {
    display: flex;
    align-items: center;
    margin-top: 8px;
    &:first-child {
      margin: 0 0 20px;
    }
    span {
      color: ${props => props.theme.darkGrey};
      margin-left: 15px;
      font-weight: 500;
      font-size: 14px;
    }
    svg {
      height: 18px;
    }
  }
  .profile__role {
    color: ${props => props.theme.darkGrey};
    font-weight: 500;
  }
  .profile__bio {
    color: ${props => props.theme.grey};
    line-height: 24px;
    letter-spacing: .1px;
    font-size: 14px;
  }
`
