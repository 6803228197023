export const TOKEN_VERIFIED = 'TOKEN_VERIFIED'
export const TOKEN_VERIFIED_FAILED = 'TOKEN_VERIFIED_FAILED'

export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUESTS_FAILED = 'GET_REQUESTS_FAILED'

export const GET_HOLIDAYS = 'GET_HOLIDAYS'
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS'
export const GET_HOLIDAYS_FAILED = 'GET_HOLIDAYS_FAILED'

export const UDATE_REQUEST = 'UDATE_REQUEST'
export const UDATE_REQUEST_SUCCESS = 'UDATE_REQUEST_SUCCESS'
export const UDATE_REQUEST_FAILED = 'UDATE_REQUEST_FAILED'

export const USER_SIGN_OUT = 'USER_SIGN_OUT'
