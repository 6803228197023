import { TOKEN_VERIFIED, TOKEN_VERIFIED_FAILED } from '../constants'

const initialState = {
  loading: true,
  currentAdmin: null,
  company: null,
  token: null,
  error: null
}

export default function company (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case TOKEN_VERIFIED:
      return {
        ...state,
        loading: false,
        currentAdmin: payload.company.cto,
        token: payload.token,
        company: payload.company,
        error: null
      }

    case TOKEN_VERIFIED_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error
      }


    default:
      return state
  }
}
