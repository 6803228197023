import {
  GET_REQUESTS,
  GET_REQUESTS_FAILED,
  UDATE_REQUEST,
  UDATE_REQUEST_SUCCESS,
  UDATE_REQUEST_FAILED
} from '../constants'

export const getLeaveRequests = _ => {
  return async dispatch => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/companies/requests`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization')
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_REQUESTS,
        payload: body
      })
    } else {
      dispatch({
        type: GET_REQUESTS_FAILED,
        payload: body
      })
    }
  }
}

export const reviewRequest = ({ status, requester, requestId }) => {
  return async dispatch => {
    dispatch({
      type: UDATE_REQUEST
    })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/companies/requests/${requestId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status, requester })
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: UDATE_REQUEST_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: UDATE_REQUEST_FAILED,
        payload: body
      })
    }
  }
}
