import React from 'react'
import format from 'date-fns/format'
import distanceInWords from 'date-fns/distance_in_words'

import { StyleBase, RowTable, RequestStatus } from './style'

const LeaveRequests = ({ requests }) => {
  
  return (
    <StyleBase>
      <RowTable className="table-header">
        <span>Employee</span>
        <span>Submitted at</span>
        <span>Start date</span>
        <span>Duration</span>
        <span>Status</span>
      </RowTable>
      {
        requests.map(request => (
          <RowTable className="table-value" key={request._id}>
            <span>
              <img src={request.requester.avatar} height={30} alt={request.requester.name}/>
              <span>{request.requester.name}</span>
            </span>
            <span>about {distanceInWords(new Date(request.createdAt), new Date())}</span>
            <span>{format(request.start_date, 'MMM. DD, YYYY')}</span>
            <span>{request.duration} days</span>
            <span>
              <RequestStatus status={request.status}>{request.status}</RequestStatus>
            </span>
          </RowTable>
        ))
      }
    </StyleBase>
  )
}


export default LeaveRequests
