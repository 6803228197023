import React from 'react'
import format from 'date-fns/format'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reviewRequest } from '../../../actions/requests'
import { StyleBase, EmptyState } from './style'
import RequestCard from '../../../components/RequestCard'

const UpComingRequests = ({ requests, reviewRequest }) => {
  if (requests.length === 0) {
    return (
      <EmptyState>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="icon-calendar-date">
          <path
            className="primary"
            fill="#909090"
            d="M5 4h14a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm0 5v10h14V9H5z"
            />
          <path
            fill="#909090"
            className="secondary"
            d="M13 13h3v3h-3v-3zM7 2a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zm10 0a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1z"
          />
        </svg>
        <p>No one has requested a leave day once they do it will automatically show up here.</p>
      </EmptyState>
    )
  }
  return (
    <StyleBase>
      {requests.map(request => (
        <RequestCard
          {...request}
          createdAt={format(request.createdAt, 'MMM. DD, YYYY')}
          start_date={format(request.start_date, 'MMM. DD, YYYY')}
          end_date={format(request.end_date, 'MMM. DD, YYYY')}
          handleApprovedRequest={() =>
            reviewRequest({
              status: 'approved',
              requester: request.requester._id,
              requestId: request._id
            })
          }
          handleRejectedRequest={() =>
            reviewRequest({
              status: 'denied',
              requester: request.requester._id,
              requestId: request._id
            })
          }
          key={request._id}
        />
      ))}
    </StyleBase>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ reviewRequest }, dispatch)

export default connect(
  null,
  mapDispatchToProps
)(UpComingRequests)
